import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import { MainNavigationProvider } from './components/Contexts/MainNavigationContext';
import App from './App';
import './i18n';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <MainNavigationProvider>
          <App />
        </MainNavigationProvider>
    </React.StrictMode>
);